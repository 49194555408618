import { settori } from "../utils/constants";

export const stripHtml = (html: string) => {
  const dom = new DOMParser().parseFromString(html, "text/html");
  return dom.body.textContent || dom.body.innerText || "";
};

export const isPrivateRoute = (pathname: string) => {
  const privateRoutes = [
    `/my-home`,
    `/my-help`,
    `/my-catalogo`,
    `/my-webinar`,
    `/my-formazione`,
    `/contatta-consulente`,
    `/attiva-prodotto`,
  ];
  return privateRoutes.includes(pathname);
};

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const getDetailPage = (productCategoryName: string) => {
  const detailPage = {
    prodotto: "catalogo",
    webinar: "webinar",
    formazione: "formazione",
  }[productCategoryName.toLowerCase()];

  return detailPage;
};

export const getSettori = () => {
  return settori.map((item) => {
    const value = item.value.concat(" ", item.label).substring(0, 74);
    return {
      value: value,
      label: `${item.value} ${item.label}`,
    };
  });
};

export const getFormatTypeDesc = (formatType: number) => {
  switch (formatType) {
    case 1:
      return "cartaceo + digitale";
    case 2:
      return "digitale";
    case 3:
      return "cartaceo";
    default:
      return "";
  }
};
