import { Link } from "react-router-dom";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { stripHtml } from "../utils/utility";
import placeholders from "../utils/placeholders";
import Skeleton from "react-loading-skeleton";
import { getDetailPage } from "../utils/utility";
import Icon from "../components/icon";
import { useSlides } from "../hooks/useQueryCustom";
import LoadingError from "../components/loading-error";
import classNames from "classnames";

type Props = {
  type?: string;
  size?: number;
};

const Slider = ({ type = "", size = 3 }: Props) => {
  useSwiper();

  const { data, isError, isLoading } = useSlides("getSlides", type, size);

  return isError ? (
    <LoadingError className="error-block--page" />
  ) : (
    <>
      {(isLoading || data?.pearsonProducts?.items.length > 0) && (
        <div className="slider">
          <div className="container-md">
            <div className="row">
              <div className="col-12">
                <div className="swiper-container position-relative w-100 px-3">
                  <Swiper
                    autoHeight={false}
                    spaceBetween={110}
                    navigation={{
                      nextEl: ".swiper-button-next",
                      prevEl: ".swiper-button-prev",
                    }}
                    pagination={{ clickable: true }}
                    modules={[Navigation, Pagination]}
                  >
                    {(data?.pearsonProducts?.items || placeholders(1)).map(
                      (item: Product, i: number) => (
                        <SwiperSlide key={i}>
                          <div className="d-md-flex">
                            <div className="slider__image">
                              {"placeholder" in item ? (
                                <Skeleton width={230} height={280} baseColor={"#fff"} />
                              ) : (
                                <Link
                                  title={item.productName}
                                  to={`/${getDetailPage(item.productCategoryName)}/${
                                    item.productId
                                  }`}
                                >
                                  <img src={item.coverImage} alt={item.productName} />
                                </Link>
                              )}
                            </div>
                            <div className="slider__content w-100">
                              <>
                                {!("placeholder" in item) ? (
                                  <div
                                    className={classNames("d-flex align-items-start", {
                                      "mb-3":
                                        item.productCategoryName !== "Prodotto" || item.newProduct,
                                    })}
                                  >
                                    {item.newProduct && (
                                      <div className="slider__tag me-3">Novità</div>
                                    )}
                                    {item.productCategoryName !== "Prodotto" && (
                                      <div className="slider__tag slider__tag--category">
                                        <Icon name={item.productCategoryName} />
                                        {item.productCategoryName}
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <Skeleton width={100} height={20} className="mb-3" />
                                )}
                                <div className="slider__title">
                                  {"placeholder" in item ? (
                                    <Skeleton width={"50%"} />
                                  ) : (
                                    <Link
                                      title={item.productName}
                                      to={`/${getDetailPage(item.productCategoryName)}/${
                                        item.productId
                                      }`}
                                    >
                                      {item.productName}
                                    </Link>
                                  )}
                                </div>

                                <div className="slider__subtitle">
                                  {"placeholder" in item ? (
                                    <Skeleton width={"25%"} />
                                  ) : (
                                    item.productSubtitle
                                  )}
                                </div>

                                {item.productCategoryName === "Prodotto" &&
                                item.descriptionEdition ? (
                                  <div className="slider__edition">
                                    {"placeholder" in item ? (
                                      <Skeleton width={"25%"} />
                                    ) : (
                                      item.descriptionEdition
                                    )}
                                  </div>
                                ) : null}

                                <div className="slider__abstract">
                                  {"placeholder" in item ? (
                                    <Skeleton count={1.5} />
                                  ) : (
                                    stripHtml(item.productAbstract)
                                  )}
                                </div>
                                {"placeholder" in item ? (
                                  <Skeleton width={125} height={38} />
                                ) : (
                                  <Link
                                    to={
                                      item.link ||
                                      `/${getDetailPage(item.productCategoryName)}/${
                                        item.productId
                                      }`
                                    }
                                    className="button button--fw-normal button--fs-16"
                                  >
                                    Scopri di più
                                  </Link>
                                )}
                              </>
                            </div>
                          </div>
                        </SwiperSlide>
                      )
                    )}
                  </Swiper>
                  <button aria-label="prodotto successivo" className="swiper-button-next"></button>
                  <button aria-label="prodotto precedente" className="swiper-button-prev"></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Slider;
