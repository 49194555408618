import { toast } from "react-toastify";

export const callBigCommerce = async (token: string, isbn: string) => {
  try {
    const response = await fetch(import.meta.env.VITE_BG_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": token,
      },
      body: JSON.stringify({
        data: [
          {
            sku: isbn,
            quantity: 1,
          },
        ],
        consent: {
          allow: ["ESSENTIAL", "TARGETING", "FUNCTIONAL"],
          deny: ["ANALYTICS"],
        },
      }),
    });
    if (!response.ok) {
      const { status } = response;
      if (status === 404) {
        toast.error("Prodotto non disponibile");
      } else if (status === 500) {
        toast.error("Sessione non valida");
      }
    } else {
      const { data } = await response.json();
      if (data.checkoutUrl) {
        window.location.href = data.checkoutUrl;
      } else {
        toast.error("Servizio non disponibile");
      }
    }
  } catch (error) {
    toast.error("Servizio non disponibile");
  }
};
