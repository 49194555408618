import Icon from "./icon";
import { getFormatTypeDesc } from "../utils/utility";
import CustomModal from "./modal";
import { useContext, useState } from "react";
import UserContext from "../context/user";
import { callBigCommerce } from "../utils/big-commerce";
import classNames from "classnames";
import { Spinner } from "./spinner";

interface Props {
  data: Format;
}

const Format = ({ data }: Props) => {
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const { user, login, checkIESSession } = useContext(UserContext);
  const [submitting, setSubmitting] = useState(false);

  const goToEcommerce = (url: string) => {
    if (user) {
      window.location.href = url;
    } else {
      setOpenPopup(true);
    }
  };

  const goToBigCommerce = async (isbn: string) => {
    setSubmitting(true);

    if (user) {
      if (checkIESSession()) {
        window.piSession.getToken((status: string, token: string) => {
          console.log("status", status);
          callBigCommerce(token, isbn);
        });
      }
    } else {
      setSubmitting(false);
      setOpenPopup(true);
    }
  };

  const gotoLogin = () => {
    login();
  };

  const price = new Intl.NumberFormat("it", { style: "currency", currency: "EUR" }).format(
    data.price
  );
  const urlEcommerce = data?.urlEcommerce;
  const ecommerceType = import.meta.env.VITE_ECOMMERCE;
  console.log("ecommerceType", ecommerceType);
  return (
    <div className="format d-flex mt-3">
      <div className="format__content">
        <div className="format__tag">Formato {getFormatTypeDesc(data.formatType)}</div>
        <h3 className="format__title">{data.title}</h3>
        {data?.pageNumber > 0 && (
          <div className="format__pages">
            <span className="format__label">Pagine</span>
            <span>{data.pageNumber}</span>
          </div>
        )}
        <div className="format__isbn">
          <span className="format__label">ISBN</span>
          <span>{data.isbn}</span>
        </div>
        {data?.price > 0 && (
          <div className="format__prices">
            <span className="format__label">Prezzo</span>
            <span>{price}</span>
          </div>
        )}
      </div>
      {data?.isEcommerce && (
        <div className="format__cta d-flex flex-column justify-content-end flex-grow-1">
          {ecommerceType === "BC" ? (
            <button
              onClick={(e) => goToBigCommerce(data.isbn)}
              title="Acquista online"
              disabled={submitting}
              className="button button--tertiary"
            >
              {submitting && <Spinner className="button__loader" />}
              <Icon
                className={classNames("icon", submitting && "invisible")}
                name="cart"
                width="29px"
                height="22px"
              />
              <span className={classNames(submitting && "invisible")}>Acquista</span>
            </button>
          ) : (
            <>
              {urlEcommerce && urlEcommerce.toLowerCase().startsWith("http") && (
                <button
                  onClick={(e) => goToEcommerce(urlEcommerce)}
                  title="Acquista online"
                  className="button button--tertiary"
                >
                  <Icon className="icon" name="cart" width="29px" height="22px" />
                  <span>Acquista</span>
                </button>
              )}
            </>
          )}
        </div>
      )}

      <CustomModal
        show={openPopup}
        headerText="Attenzione"
        type="confirm"
        confirmButtonText="Login / Registrati"
        scrollable={false}
        onConfirm={gotoLogin}
        onHide={() => setOpenPopup(false)}
      >
        <div>Per acquistare un prodotto devi fare login</div>
      </CustomModal>
    </div>
  );
};

export default Format;
