import { Fragment } from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { stripHtml } from "../utils/utility";
import dayjs from "dayjs";
import { getDetailPage } from "../utils/utility";

interface Props {
  type: string;
  data: Product | Placeholder;
}

const Card: React.FC<Props> = ({ data, type }: Props) => {
  const detailPage = getDetailPage(type);

  return (
    <div className="card d-flex flex-column flex-lg-row">
      <div className="card__image">
        {"placeholder" in data ? (
          <Skeleton height={180} />
        ) : (
          <>
            {data.newProduct && <div className="card__tag">Novità</div>}
            {!data.newProduct && data.tags && (
              <div className="card__tag">{data.tags[0]?.tagName}</div>
            )}
            {data.coverImage && (
              <Link title={data.productName} to={`/${detailPage}/${data.productId}`}>
                <img alt="cover" src={data.coverImage} />
              </Link>
            )}
          </>
        )}
      </div>
      <div className="card__content d-flex flex-column pt-3 pt-lg-0 ps-lg-4 w-100">
        {"placeholder" in data ? (
          <>
            <h2 className="card__title">
              <Skeleton />
            </h2>
            <Skeleton count={3.5} />
          </>
        ) : (
          <>
            <h2 className="card__title">
              <Link title={data.productName} to={`/${detailPage}/${data.productId}`}>
                {data.productName}
              </Link>
            </h2>
            {data?.productSubtitle && (type === "prodotto" || type === "formazione") ? (
              <h3 className="card__subtitle">{data.productSubtitle}</h3>
            ) : null}
            {type === "webinar"
              ? data.publishDate && (
                  <div className="card__date">
                    <span className="card__label">Date:</span>
                    <span>{dayjs(data.publishDate).format("DD/MM/YYYY")}</span>
                  </div>
                )
              : null}
            {type === "prodotto" ? (
              <div className="card__edition">
                {data.descriptionEdition && `${data.descriptionEdition}`}
              </div>
            ) : null}
            {type === "prodotto" ? (
              <div className="card__author">
                {data?.author?.map((author: Author, i: number) => (
                  <Fragment key={i}>
                    {author.authorName} {author.authorSurname}
                    {i < data.author.length - 1 ? <span> - </span> : ""}
                  </Fragment>
                ))}
              </div>
            ) : null}
            {type === "webinar" ? (
              <div className="card__speaker">
                <strong>Relatore: </strong>
                {data?.author?.map((author: Author, i: number) => (
                  <Fragment key={i}>
                    {author.authorName} {author.authorSurname}
                    {i < data.author.length - 1 ? <span> - </span> : ""}
                  </Fragment>
                ))}
              </div>
            ) : null}
            {data.productAbstract && (
              <div className="card__description">{stripHtml(data.productAbstract)}</div>
            )}
            <div className="card__subject">
              {data.subjectCategoryName ? (
                <>
                  <span className="card__label">Materia:</span>
                  <span>{data.subjectCategoryName}</span>
                </>
              ) : (
                <Skeleton width={"50%"} />
              )}
            </div>
            {type === "prodotto" && data.publishDate ? (
              <>
                <div className="card__year">
                  <span className="card__label">Anno:</span>
                  <span>{"publishDate" in data && new Date(data.publishDate).getFullYear()}</span>
                </div>
                {data.formats?.length === 1 && !!data.formats[0]?.price && (
                  <div className="card__year">
                    <span className="card__label">Prezzo:</span>
                    <span>
                      {new Intl.NumberFormat("it", { style: "currency", currency: "EUR" }).format(
                        data.formats[0]?.price
                      )}
                    </span>
                  </div>
                )}
              </>
            ) : null}
            {data.productId && (
              <Link
                className="button button--secondary align-self-end mt-auto"
                to={`/${detailPage}/${data.productId}`}
              >
                Visualizza
              </Link>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Card;
