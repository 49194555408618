import { gql } from "graphql-request";

export const FRAGMENT_PRODUCT = gql`
  fragment ProductDetail on PearsonProduct {
    accessType
    coverImage
    descriptionEdition
    expirationDate
    draft
    groupId
    index
    learningType
    link
    productAbstract
    productCategory
    productCategoryName
    productDescription
    productId
    productName
    productSubtitle
    publishDate
    resources
    subjectCategory
    subjectCategoryName
    moodleId
    defaultProduct
    typeElearningSystem
    activationLink
    accessLink
    author {
      authorBio
      authorId
      authorName
      authorSurname
    }
    formats {
      formatId
      groupId
      formatType
      isEcommerce
      urlEcommerce
      pageNumber
      price
      title
      isbn
    }
    tags {
      tagId
      tagName
    }
  }
`;

export const FRAGMENT_PRODUCT_CARD_AND_SLIDE = gql`
  fragment ProductDetail on PearsonProduct {
    coverImage
    descriptionEdition
    learningType
    productAbstract
    productId
    productName
    productSubtitle
    productCategory
    newProduct
    important
    productCategoryName
    subjectCategory
    subjectCategoryName
    publishDate
    moodleId
    defaultProduct
    typeElearningSystem
    activationLink
    accessLink
    author {
      authorId
      authorName
      authorSurname
    }
    formats {
      price
    }
    tags {
      tagId
      tagName
    }
  }
`;

export const FRAGMENT_COURSE = gql`
  fragment CourseDetail on PearsonCourse {
    code
    courseId
    createDate
    groupId
    isIndipendent
    name
    url
    productId
  }
`;
